/* INITIALZE CRISP */
(function () {
  window.$crisp = [];
  window.CRISP_WEBSITE_ID = "dbaa2cd5-3f5e-4006-9695-7f73e01579c7";
  (function () {
    const d = document;
    const s = d.createElement("script");
    s.src = "https://client.crisp.chat/l.js";
    s.async = 1;
    d.getElementsByTagName("head")[0].appendChild(s);
  })();
  window.$crisp.push(["set", "session:segments", [["app_survey"]]]);
})();
