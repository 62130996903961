import * as Sentry from "@sentry/browser";
import makeDebug from "debug";
import { has } from "lodash";
import store from "store";

const debug = makeDebug("sentry");
let dsn = "";
const isStaging =
  store.get("isStaging") === true ||
  window.location.host.split(".")[0] === "staging";
if (process.env.NODE_ENV === "production" && !isStaging) {
  dsn =
    "https://47b9b1c0eb0fd1b8012a1345842828e7@o4507270694764544.ingest.de.sentry.io/4507270698434640";
  debug(
    `Sentry enabled. DSN: ${dsn}. App Version: ${process.env.REACT_APP_VERSION}. Environment: ${process.env.REACT_APP_ENVIRONMENT}`
  );
} else {
  debug("Not using sentry because of development mode");
}

const isDevEnvironment = window.location.host.startsWith("survey.dev.");
const isProductionVersion = process.env.NODE_ENV === "production";

let environment = "development";
if (!isDevEnvironment && isProductionVersion) {
  environment = "production";
}

Sentry.init({
  dsn,
  environment,
  release: process.env.REACT_APP_VERSION || "development",
  normalizeDepth: 10,
});

export function reportError(err: Error, showReportDialog = false) {
  const isNetworkError =
    has(err, "crossDomain") && has(err, "method") && has(err, "url");
  if (process.env.NODE_ENV === "production") {
    debug("Capture error with sentry", err, showReportDialog, isNetworkError);
    Sentry.captureException(err);
    if (showReportDialog && isNetworkError === false) {
      Sentry.showReportDialog();
    }
  } else {
    console.warn("Not reporting following error:");
    console.error(err);
    debug(
      "Don't capture error with sentry in non-production",
      err,
      showReportDialog,
      isNetworkError
    );
  }
}

export function handleReactError(
  error: Error,
  info: { componentStack: string }
) {
  debug("Capture react error", error, info.componentStack);
  Sentry.withScope((scope) => {
    scope.setExtra("componentStack", info.componentStack);
    Sentry.captureException(error);
  });
}

export function handleSWRError(error: Error, key: string) {
  console.error("swr error", error, key);
  Sentry.withScope((scope) => {
    scope.setExtra("key", key);
    Sentry.captureException(error);
  });
}

export default Sentry;
