import { combineReducers, Reducer } from "redux";
import { all, fork } from "redux-saga/effects";
import { surveyReducer } from "./survey/reducer";
import surveySaga from "./survey/sagas";
import { SurveyState } from "./survey/types";
import { surveyAnswersReducer } from "./surveyAnswers/reducer";
import surveyAnswersSaga from "./surveyAnswers/sagas";
import { SurveyAnswersState } from "./surveyAnswers/types";

// The top-level state object
export interface ApplicationState {
  survey: SurveyState;
  surveyAnswers: SurveyAnswersState;
}

export const createRootReducer = () => {
  const appReducer = combineReducers({
    survey: surveyReducer,
    surveyAnswers: surveyAnswersReducer,
  });
  const rootReducer: Reducer<ApplicationState> = (
    state: ApplicationState | undefined,
    action: any
  ) => {
    return appReducer(state, action);
  };
  return rootReducer;
};

// Here we use `redux-saga` to trigger actions asynchronously. `redux-saga` uses something called a
// "generator function", which you can read about here:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/function*
export function* rootSaga() {
  yield all([fork(surveySaga), fork(surveyAnswersSaga)]);
}
