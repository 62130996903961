import { PublicSurveyDto } from "types/PublicSurvey";

export enum SurveyActionTypes {
  LOAD_SURVEY_REQUEST = "@@survey/LOAD_SURVEY_REQUEST",
  LOAD_SURVEY_ERROR = "@@survey/LOAD_SURVEY_ERROR",
  LOAD_SURVEY_SUCCESS = "@@survey/LOAD_SURVEY_SUCCESS",
}

export interface SurveyState {
  readonly survey: PublicSurveyDto | null;
  readonly isLoading: boolean;
  readonly hasLoaded: boolean;
  readonly error: Error | null;
  readonly token: string | null;
  readonly tokenType: string | null;
}
