import { PublicSurveyDto } from "types/PublicSurvey";
import { action } from "typesafe-actions";
import { SurveyActionTypes } from "./types";

export type TokenType = "instant-feedback" | "preview" | "link" | "code";
//### load survey
export const loadSurveyRequest = (
  tokenType: TokenType,
  token: string,
  keepNavigation: boolean
) =>
  action(SurveyActionTypes.LOAD_SURVEY_REQUEST, {
    tokenType,
    token,
    keepNavigation,
  });
export const loadSurveyError = (err: Error) =>
  action(SurveyActionTypes.LOAD_SURVEY_ERROR, err);
export const loadSurveySuccess = (survey: PublicSurveyDto) =>
  action(SurveyActionTypes.LOAD_SURVEY_SUCCESS, survey);
