import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import moment from "moment";
import { initReactI18next } from "react-i18next";

import bg from "../translations/bg.json";
import bs from "../translations/bs.json";
import cs from "../translations/cs.json";
import da from "../translations/da.json";
import de from "../translations/de.json";
import en from "../translations/en.json";
import es from "../translations/es.json";
import fi from "../translations/fi.json";
import fr from "../translations/fr.json";
import hu from "../translations/hu.json";
import it from "../translations/it.json";
import ja from "../translations/ja.json";
import lt from "../translations/lt.json";
import pl from "../translations/pl.json";
import ro from "../translations/ro.json";
import ru from "../translations/ru.json";
import sl from "../translations/sl.json";
import sv from "../translations/sv.json";
import tr from "../translations/tr.json";
import uk from "../translations/uk.json";
import zhHans from "../translations/zh-Hans.json";

export async function initI18n() {
  i18n.use(LanguageDetector).use(initReactI18next);
  await i18n.init(
    {
      debug: process.env.NODE_ENV !== "production",
      react: {
        useSuspense: false,
      },
      resources: {
        en: { common: en },
        de: { common: de },
        pl: { common: pl },
        ro: { common: ro },
        ru: { common: ru },
        tr: { common: tr },
        cs: { common: cs },
        es: { common: es },
        zh: { common: zhHans },
        da: { common: da },
        fr: { common: fr },
        ja: { common: ja },
        fi: { common: fi },
        hu: { common: hu },
        sv: { common: sv },
        lt: { common: lt },
        bg: { common: bg },
        uk: { common: uk },
        it: { common: it },
        bs: { common: bs },
        sl: { common: sl },
      },
      fallbackLng: "en",
      interpolation: {
        escapeValue: false, // not needed for react
        formatSeparator: ",",
        format: function (value: any, format: any, lng: any) {
          if (format === "uppercase") return value.toUpperCase();
          if (moment(value).isValid()) {
            return moment(value).locale(lng).format(format);
          }
          return value;
        },
      },
    },
    () => {
      moment.locale(i18n.resolvedLanguage);
    }
  );
}
export default i18n;

export const SUPPORTED_LANGUAGES = [
  "en",
  "de",
  "pl",
  "ro",
  "ru",
  "tr",
  "cs",
  "es",
  "zh",
  "da",
  "fr",
  "ja",
  "fi",
  "hu",
  "sv",
  "lt",
  "bg",
  "uk",
  "it",
  "bs",
  "sl",
];
