export enum SurveyAnswersActionTypes {
  START_SURVEY = "@@surveyAnswers/START_SURVEY",
  UPDATE_VALUE = "@@surveyAnswers/UPDATE_VALUE",
  UPDATE_COMMENT = "@@surveyAnswers/UPDATE_COMMENT",
  UPDATE_FOLLOW_UP_INFORMATION = "@@surveyAnswers/UPDATE_FOLLOW_UP_INFORMATION",
  UPDATE_SEGMENT_SELECTION = "@@surveyAnswers/UPDATE_SEGMENT_SELECTION",
  UPDATE_SKIP_REASON = "@@surveyAnswers/UPDATE_SKIP_INFORMATION",
  SUBMIT_SURVEY_REQUEST = "@@surveyAnswers/SUBMIT_SURVEY_REQUEST",
  SUBMIT_SURVEY_SUCCESS = "@@surveyAnswers/SUBMIT_SURVEY_SUCCESS",
  SUBMIT_SURVEY_ERROR = "@@surveyAnswers/SUBMIT_SURVEY_ERROR",
  UPDATE_VISIBLE_ID = "@@surveyAnswers/UPDATE_VISIBLE_ID",
}

export type SegmentSelection = Record<string, string>;

export interface SurveyAnswerElements {
  readonly values: Record<string, number>;
  readonly comments: Record<string, string>;
  readonly segmentSelection: SegmentSelection;
  readonly followUpInformation: Record<string, string>;
  readonly skipReasons: Record<string, string | null>;
  readonly visibleId: string | null;
}

export interface SurveyAnswersState extends SurveyAnswerElements {
  readonly isSubmitting: boolean;
  readonly submitError: Error | null;
  readonly hasSubmitted: boolean;
}
