import configureStore from "configureStore";
import { createBrowserHistory, History } from "history";
import i18n from "i18n";
import { unregister as unregisterServiceWorker } from "lib/serviceWorker";
import React from "react";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { SWRConfig } from "swr";
import { APIContext, configureApi, setupErrorHandler } from "utils/api";
import { getAPIUrl } from "utils/getAPIUrl";
import { logStartupBanner } from "utils/startupBanner";
import "./lib/liveChat";
import { handleSWRError, reportError } from "./lib/sentry";

export function startApp() {
  try {
    logStartupBanner();
    unregisterServiceWorker();

    // @ts-ignore
    const initialState = window.INITIAL_REDUX_STATE;
    const api = configureApi(getAPIUrl());
    const history: History = createBrowserHistory();
    const store = configureStore(initialState, { api, history });
    setupErrorHandler(api);

    // Render Setup
    const MOUNT_NODE = document.getElementById("root");
    const root = createRoot(MOUNT_NODE!);
    let render = (AppElement: React.FC): void => {
      root.render(
        <APIContext.Provider value={api}>
          <SWRConfig value={{ onError: handleSWRError }}>
            <Router history={history}>
              <Provider store={store}>
                <I18nextProvider i18n={i18n}>
                  <AppElement />
                </I18nextProvider>
              </Provider>
            </Router>
          </SWRConfig>
        </APIContext.Provider>
      );
    };

    // ======================================================== Go!
    const app = require("./app").default;
    render(app);
    // ========================================================= HOT RELOAD
    if (module.hot && process.env.NODE_ENV !== "production") {
      module.hot.accept("./app", () => {
        const NextApp = require("./app").default;
        render(NextApp);
      });
    }
  } catch (err) {
    reportError(err as Error);
  }
}
