import store from "store";

export function getAPIUrl(includeProtocol = true) {
  const isDevEnvironment = window.location.host.startsWith("survey.dev.");
  const isProductionVersion = process.env.NODE_ENV === "production";
  if (store.get("local_dev", false)) {
    return `${includeProtocol ? "http://" : ""}localhost:3000/api`;
  }
  if (isProductionVersion) {
    if (isDevEnvironment) {
      return `${includeProtocol ? "https://" : ""}api.dev.kultifyapp.com`;
    }
    return `${includeProtocol ? "https://" : ""}api.kultifyapp.com`;
  } else {
    if (store.get("local_dev", false)) {
      return `${includeProtocol ? "http://" : ""}localhost:3000/api`;
    }
    return `${includeProtocol ? "https://" : ""}api.dev.kultifyapp.com`;
  }
}
