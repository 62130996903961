import "core-js/actual/promise";
import "core-js/actual/symbol";
import "core-js/features/array/find";
import "core-js/features/array/includes";
import "core-js/features/string/repeat";
import "core-js/features/string/starts-with";
import { initI18n } from "i18n";
import "react-app-polyfill/stable";
import { startApp } from "startApp";

initI18n().finally(() => {
  startApp();
});
