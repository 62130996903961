import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import * as Axios from "axios";
// If you use react-router, don't forget to pass in your history type.
import { History } from "history";
import Sentry from "lib/sentry";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AnyAction, applyMiddleware, compose, createStore, Store } from "redux";
import createSagaMiddleware from "redux-saga";
import createSentryMiddleware from "redux-sentry-middleware";
// Import the state interface and our combined reducers/sagas.
import { ApplicationState, createRootReducer, rootSaga } from "./reduxStore";

export interface Context {
  api: Axios.AxiosInstance;
  history: History;
}

export default function configureStore(
  initialState: ApplicationState,
  context: Context
): Store<ApplicationState> {
  // create the redux-saga middleware
  const sagaMiddleware = createSagaMiddleware({
    context,
  });

  const composeEnhancers =
    (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
  // We'll create our store with the combined reducers/sagas, and the initial Redux state that
  // we'll be passing from our entry point.
  const store = createStore(
    createRootReducer(),
    initialState,
    composeEnhancers(
      applyMiddleware(
        sagaMiddleware,
        //@ts-expect-error sentry version
        createSentryMiddleware(Sentry, {
          breadcrumbDataFromAction: (action) => action,
          // send current value of the answers to sentry
          stateTransformer: ({ surveyAnswers, survey }: ApplicationState) => ({
            surveyAnswers: surveyAnswers,
            surveyId: survey.survey?._id,
          }),
        })
      )
    )
  );

  // Don't forget to run the root saga, and return the store object.
  sagaMiddleware.run(rootSaga);
  return store;
}

export const useAppSelector: TypedUseSelectorHook<ApplicationState> =
  useSelector;
export type DispatchAction<T extends AnyAction = Action> = ThunkDispatch<
  ApplicationState,
  undefined,
  T
>;
export const useThunkDispatch: () => DispatchAction = useDispatch;
export type ApplicationStore = Store<ApplicationState>;
