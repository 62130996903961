export function logStartupBanner() {
  console.log(
    `%c
  ##    ## ##     ## ##       ######## #### ######## ##    ##
  ##   ##  ##     ## ##          ##     ##  ##        ##  ##               Built with React, Redux, Moleculer and others.
  ##  ##   ##     ## ##          ##     ##  ##         ####                See our techstack: https://stackshare.io/kultify/kultify
  #####    ##     ## ##          ##     ##  ######      ##                 Made with ❤️ in Nürnberg
  ##  ##   ##     ## ##          ##     ##  ##          ##                 Version ${process.env.REACT_APP_VERSION}
  ##   ##  ##     ## ##          ##     ##  ##          ##                 For more visit https://kultify.de
  ##    ##  #######  ########    ##    #### ##          ##
  `,
    "color: #36A0F2"
  );
}
