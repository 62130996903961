import {
  Box,
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { makeKultifyTheme } from "theme";
import { ReactComponent as ErrorImage } from "./error.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  image: {
    maxHeight: "14rem",
    width: "auto",
  },
  typography: {},
}));

const AppError = () => {
  const { t, i18n } = useTranslation("common", { keyPrefix: "appError" });
  const classes = useStyles();
  document.body.dir = i18n.dir();
  return (
    <ThemeProvider theme={makeKultifyTheme(i18n.dir())}>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <div className={classes.root}>
          <Box marginBottom={2}>
            <ErrorImage className={classes.image} />
          </Box>
          <Typography variant="h6">{t("title")}</Typography>
          <Typography
            sx={{ marginBottom: 2 }}
            className={classes.typography}
            variant="subtitle2"
            color="textSecondary"
          >
            {t("subtitle")}
          </Typography>
          <Button href="/" variant="contained" color="primary">
            {t("btnReload")}
          </Button>
        </div>
      </StyledEngineProvider>
    </ThemeProvider>
  );
};

export default AppError;
