import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import loadable from "@loadable/component";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { jssPreset, StylesProvider } from "@mui/styles";
import AppError from "components/AppError";
import NotFound from "components/NotFound";
import { create } from "jss";
import rtl from "jss-rtl";
import { handleReactError } from "lib/sentry";
import React from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router-dom";
import { prefixer } from "stylis";
import stylisRTLPlugin from "stylis-plugin-rtl";
import { makeKultifyTheme } from "theme";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";

const LoadableSurveyPage = loadable(() => import("pages/Survey"));
const LoadableGotCodePage = loadable(() => import("pages/GotCode/GotCodePage"));
const LoadablePeerFeedbackPage = loadable(
  () => import("modules/peerFeedback/page/PeerFeedbackPage")
);

const routes = () => (
  <Switch>
    <Route path="/" exact component={LoadableGotCodePage} />
    <Route path="/survey" component={LoadableSurveyPage} />
    <Route path="/peer-feedback" component={LoadablePeerFeedbackPage} />
    <Route component={NotFound} />
  </Switch>
);

interface Props {}

export const App: React.FC<Props> = () => {
  const { i18n } = useTranslation();
  const theme = React.useMemo(() => {
    document.body.dir = i18n.dir();
    return makeKultifyTheme(i18n.dir());
  }, [i18n]);
  const jss = create({
    plugins: [...jssPreset().plugins, rtl()],
  });
  const cacheRtl = createCache({
    key: theme.direction === "rtl" ? "cssrtl" : "cssltr",
    //@ts-expect-error wrong type
    stylisPlugins:
      theme.direction === "rtl" ? [stylisRTLPlugin, prefixer] : undefined,
  });
  return (
    <ThemeProvider theme={theme}>
      <CacheProvider
        //@ts-expect-error wrong type
        value={cacheRtl}
      >
        <StylesProvider jss={jss}>
          <CssBaseline />
          <QueryParamProvider adapter={ReactRouter5Adapter}>
            {routes()}
          </QueryParamProvider>
        </StylesProvider>
      </CacheProvider>
    </ThemeProvider>
  );
};

export default withErrorBoundary(App, {
  FallbackComponent: AppError,
  onError: handleReactError,
});
