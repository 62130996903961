import { Reducer } from "redux";
import { SurveyActionTypes, SurveyState } from "./types";

export const initialState: SurveyState = {
  survey: null,
  hasLoaded: false,
  isLoading: false,
  error: null,
  token: null,
  tokenType: null,
};

const reducer: Reducer<SurveyState> = (
  state = initialState,
  action
): SurveyState => {
  switch (action.type) {
    // ### loading
    case SurveyActionTypes.LOAD_SURVEY_REQUEST: {
      return {
        ...state,
        isLoading: true,
        hasLoaded: false,
        error: null,
        survey: null,
        token: action.payload.token,
        tokenType: action.payload.tokenType,
      };
    }
    case SurveyActionTypes.LOAD_SURVEY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        hasLoaded: true,
        error: null,
        survey: action.payload,
      };
    }
    case SurveyActionTypes.LOAD_SURVEY_ERROR: {
      return {
        ...state,
        isLoading: false,
        hasLoaded: false,
        error: action.payload,
        survey: null,
        token: null,
        tokenType: null,
      };
    }
    default: {
      return state;
    }
  }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as surveyReducer };
