import { produce } from "immer";
import { cloneDeep, isNumber, isString, merge } from "lodash";
import { Reducer } from "redux";
import { SurveyAnswersActionTypes, SurveyAnswersState } from "./types";

export const initialState: SurveyAnswersState = {
  comments: {},
  values: {},
  segmentSelection: {},
  followUpInformation: {},
  skipReasons: {},
  hasSubmitted: false,
  isSubmitting: false,
  submitError: null,
  visibleId: null,
};

const reducer: Reducer<SurveyAnswersState> = (
  state = initialState,
  action
): SurveyAnswersState => {
  switch (action.type) {
    case SurveyAnswersActionTypes.UPDATE_VALUE: {
      const newState = cloneDeep(state);
      if (isNumber(action.payload.value)) {
        newState.values[action.payload.answerId] = action.payload.value;
      } else {
        delete newState.values[action.payload.answerId];
      }
      return newState;
    }
    case SurveyAnswersActionTypes.UPDATE_COMMENT: {
      const newState = cloneDeep(state);
      if (isString(action.payload.comment)) {
        newState.comments[action.payload.answerId] = action.payload.comment;
      } else {
        delete newState.comments[action.payload.answerId];
      }
      return newState;
    }
    case SurveyAnswersActionTypes.UPDATE_SEGMENT_SELECTION: {
      const newState = cloneDeep(state);
      merge(newState.segmentSelection, action.payload.value);
      return newState;
    }
    case SurveyAnswersActionTypes.UPDATE_FOLLOW_UP_INFORMATION: {
      const newState = cloneDeep(state);
      merge(newState.followUpInformation, {
        [action.payload.key]: action.payload.value,
      });
      return newState;
    }
    case SurveyAnswersActionTypes.UPDATE_SKIP_REASON: {
      const newState = cloneDeep(state);
      merge(newState.skipReasons, {
        [action.payload.key]: action.payload.value,
      });
      return newState;
    }
    case SurveyAnswersActionTypes.UPDATE_VISIBLE_ID: {
      const newState = produce(state, (draft) => {
        draft.visibleId = action.payload.visibleId;
      });
      return newState;
    }
    // submitting
    case SurveyAnswersActionTypes.SUBMIT_SURVEY_REQUEST: {
      return {
        ...state,
        isSubmitting: true,
        submitError: null,
      };
    }
    case SurveyAnswersActionTypes.SUBMIT_SURVEY_SUCCESS: {
      return {
        ...state,
        isSubmitting: false,
        hasSubmitted: true,
        submitError: null,
      };
    }
    case SurveyAnswersActionTypes.SUBMIT_SURVEY_ERROR: {
      return {
        ...state,
        isSubmitting: false,
        hasSubmitted: false,
        submitError: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as surveyAnswersReducer };
