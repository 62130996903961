import { action } from "typesafe-actions";
import { SurveyAnswersActionTypes } from "./types";

export const startSurvey = () => action(SurveyAnswersActionTypes.START_SURVEY);
export const updateAnswerValue = (answerId: string, value: number | null) =>
  action(SurveyAnswersActionTypes.UPDATE_VALUE, { answerId, value });
export const updateAnswerComment = (answerId: string, comment: string | null) =>
  action(SurveyAnswersActionTypes.UPDATE_COMMENT, { answerId, comment });
export const updateSegmentSelection = (selection: Record<string, string>) =>
  action(SurveyAnswersActionTypes.UPDATE_SEGMENT_SELECTION, {
    value: selection,
  });
export const updateFollowUpInformation = (key: string, value: string) =>
  action(SurveyAnswersActionTypes.UPDATE_FOLLOW_UP_INFORMATION, {
    key,
    value,
  });
export const updateVisibleId = (visibleId: string) =>
  action(SurveyAnswersActionTypes.UPDATE_VISIBLE_ID, { visibleId });

export const updateSkipReason = (key: string, value: string | null) =>
  action(SurveyAnswersActionTypes.UPDATE_SKIP_REASON, { key, value });

export const submitSurveyRequest = () =>
  action(SurveyAnswersActionTypes.SUBMIT_SURVEY_REQUEST);
export const submitSurveySuccess = () =>
  action(SurveyAnswersActionTypes.SUBMIT_SURVEY_SUCCESS);
export const submitSurveyError = (error: Error) =>
  action(SurveyAnswersActionTypes.SUBMIT_SURVEY_ERROR, error);
