import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import React from "react";
export function setupErrorHandler(api: AxiosInstance) {
  api.interceptors.response.use(
    (response: AxiosResponse<any>) => {
      return response;
    },
    (error: AxiosError) => {
      if (error.response) {
        console.error("api error catched", error.response);
        return Promise.reject(error.response.data);
      } else {
        console.error("transport error catched", error);
        return Promise.reject(error);
      }
    }
  );
}

export function configureApi(apiUrl: string) {
  const axiosInstance = axios.create({
    baseURL: apiUrl,
    timeout: 10000,
  });
  return axiosInstance;
}
export const APIContext = React.createContext<AxiosInstance>(axios);
