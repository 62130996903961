import "@fontsource/nunito/300.css";
import "@fontsource/nunito/400.css";
import "@fontsource/nunito/500.css";
import "@fontsource/nunito/600.css";
import "@fontsource/nunito/700.css";
import "@fontsource/nunito/800.css";
import { createTheme, Direction, Theme } from "@mui/material";

export interface KultifyOptions {
  palette: typeof kultifyThemeVars.colors;
  statusColors: Record<string, string>;
}

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module "@mui/material/styles" {
  interface Theme {
    kultify: KultifyOptions;
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    kultify: KultifyOptions;
  }
}

export const kultifyThemeVars = {
  colors: {
    //white
    white: "white",
    // black
    black: "#2C2C3A",
    grayA: "#4B4B5E",
    grayB: "#80818E",
    grayC: "#B7B7BE",
    grayD: "#D2D2D6",
    grayE: "#ECEDEE",
    grayF: "#F5F6F6",
    // dark blue
    darkBlueDarkest: "#0022DA",
    darkBlueDark: "#1435E4",
    darkBlue: "#3850E7",
    darkBlueBright: "#7583EE",
    // blue
    blueDarkest: "#1175C7",
    blueDark: "#1A8FF0",
    blue: "#36A0F2",
    blueLight: "#66B5F2",
    // light blue
    lightBlue: "#8ADFF3",
    // green
    darkGreenDarkest: "#1D9D53",
    darkGreen: "#37C975",
    greenDarkest: "#62AB46",
    green: "#96DD7A",
    greenLight: "#BBEB9C",
    lightGreen: "#CAF0A9",
    // red
    redDark: "#DA280A",
    red: "#EA5041",
    redLight: "#FA756A",
    redBrightest: "#FB9D95",
    // yellow
    yellowDark: "#D4AC06",
    yellow: "#F2D467",
    yellowLight: "#FEE079",
    yellowBrightest: "#FFEBAA",
    // orange
    orange: "#EF9865",
    orangeDark: "#D16A2C",
    orangeLight: "#FCAD81",
    // rose
    rose: "#F4919A",
    // pink
    pinkDarkest: "#961944",
    pinkDark: "#B83162",
    pink: "#CF527F",
    pinkLight: "#E378A0",
    // silver
    boxSilver: "#EDF1FB",
    // violet
    violet: "#933CA2",
    // purple
    purple: "#6A47A9",
    // basic analysis
    basicAnalysisBackground: "white",
    // Ribbon
    ribbonGray: "#94949C",
    // TAG Color
    tagBackground: "#80818E",
  },
};

const DEFAULT_PRIMARY_COLOR_PALETTE = {
  light: kultifyThemeVars.colors.lightBlue,
  main: kultifyThemeVars.colors.blue,
  dark: kultifyThemeVars.colors.darkBlue,
  contrastText: "#fff",
};

function makePrimaryColorPalette(primaryColor: string) {
  return {
    // light: lighten(primaryColor, 0.2),
    main: primaryColor,
    // dark: darken(kultifyTheme.colors.darkBlue, 0.2),
    // contrastText: "#fff",
  };
}

export const makeKultifyTheme = (direction: Direction, primaryColor?: string) =>
  createTheme({
    direction,
    typography: {
      fontFamily: "Nunito,Roboto,sans-serif",
      body2: {
        fontWeight: 500,
      },
      h6: {
        fontWeight: 600,
      },
      subtitle2: {
        fontWeight: 600,
      },
      button: {
        fontWeight: 600,
      },
    },
    palette: {
      primary: primaryColor
        ? makePrimaryColorPalette(primaryColor)
        : DEFAULT_PRIMARY_COLOR_PALETTE,
      secondary: {
        light: kultifyThemeVars.colors.green,
        main: kultifyThemeVars.colors.darkGreen,
        dark: kultifyThemeVars.colors.darkGreenDarkest,
        contrastText: "#fff",
      },
      error: {
        light: kultifyThemeVars.colors.redLight,
        main: kultifyThemeVars.colors.red,
        dark: kultifyThemeVars.colors.redDark,
        contrastText: "#fff",
      },
    },
    kultify: {
      palette: {
        ...kultifyThemeVars.colors,
      },
      statusColors: {
        unknown: kultifyThemeVars.colors.grayC,
        ok: kultifyThemeVars.colors.greenDarkest,
        notice: kultifyThemeVars.colors.blue,
        disrupted: kultifyThemeVars.colors.yellowDark,
        down: kultifyThemeVars.colors.red,
      },
    },
  });

export type KultifyTheme = ReturnType<typeof makeKultifyTheme>;
