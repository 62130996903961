import { Context } from "configureStore";
import makeDebug from "debug";
import {
  all,
  call,
  fork,
  getContext,
  put,
  takeEvery,
} from "redux-saga/effects";
import { updateSegmentSelection } from "reduxStore/surveyAnswers/actions";
import { PublicSurveyDto } from "types/PublicSurvey";
import {
  loadSurveyError,
  loadSurveyRequest,
  loadSurveySuccess,
} from "./actions";
import { SurveyActionTypes } from "./types";

const debug = makeDebug("store:survey:saga");

// ### loading
function* handleLoadSurvey(action: ReturnType<typeof loadSurveyRequest>) {
  debug("handle load survey", action);
  const api: Context["api"] = yield getContext("api");
  const history: Context["history"] = yield getContext("history");
  try {
    if (action.payload.keepNavigation !== true) {
      // navigate to start to prevent empty submits etc.
      const currentSearch = history.location.search;
      yield history.replace("/survey" + currentSearch);
    }
    const { data } = yield call(api.get, `/public/survey`, {
      params: {
        token: action.payload.token,
        tokenType: action.payload.tokenType,
      },
    });
    yield put(loadSurveySuccess(data));
    const publicSurvey = data as PublicSurveyDto;
    if (data.tokenSegmentSelection) {
      yield put(
        updateSegmentSelection(
          publicSurvey.tokenSegmentSelection as Record<string, string>
        )
      );
    }
  } catch (err) {
    yield put(loadSurveyError(err as Error));
  }
}

function* watchLoadSurveyRequest() {
  yield takeEvery(SurveyActionTypes.LOAD_SURVEY_REQUEST, handleLoadSurvey);
}

function* surveySaga() {
  yield all([fork(watchLoadSurveyRequest)]);
}

export default surveySaga;
